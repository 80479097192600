<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">BlockBase</span>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</span>
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">Last Updated: {{ lastUpdate }}</p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>
          Please read these Terms of Service (the “<strong>Terms</strong>”) and
          our <router-link to="/privacy">Privacy Notice</router-link>
          (“<strong>Privacy Notice</strong>”) carefully because they govern your use of the {{ companyFull }} (“<strong>{{ company }}</strong>”) website located at {{ site }} (the “<strong>Site</strong>”), {{ company }}’ email2Notion service, {{ company }}’ embeddable widget services, and any widgets generated by {{ company }}’ services for you based on your User Content (defined below) (“<strong>Generated Content</strong>”). To make these Terms easier to read, the Site, our services, and the Generated Content are collectively called the “<strong>Services</strong>.”
        </p>
        <p>
          <strong>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND {{ company }} THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY </strong><router-link to="/terms#16">
            <strong>SECTION 16</strong>
          </router-link><strong> “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.</strong>
        </p>
        <p><strong>1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Agreement to Terms.</strong> By using our Services, you agree to be bound by these Terms. If you do not agree to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms. In that case, “you” and “your” will refer to that entity.</p>
        <p><strong>2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Privacy Notice.</strong> Please review our Privacy Notice, which also governs your use of the Services, for information on how we collect, use and share your information.</p>
        <p><strong>3. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Changes to these Terms or the Services.</strong> We may update the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site and/or may also send other communications. It’s important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms it means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p>
        <p><strong>4. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Who May Use the Services?</strong></p>
        <p>You may use the Services only if you are 18 years or older and capable of forming a binding contract with {{ company }}, and not otherwise barred from using the Services under applicable law.</p>
        <p>For certain features of the Services you will need an account. It is important that you provide us with accurate, complete and current account information and keep this information up to date. If you don’t, we might have to suspend or terminate your account. To protect your account, keep the account details and password confidential, and notify us right away of any unauthorized use. You are responsible for all activities that occur under your account.</p>
        <p><strong>5. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Feedback.</strong> We appreciate feedback, comments, ideas, proposals and suggestions for improvements to the Services (“<strong>Feedback</strong>”). If you choose to submit Feedback, you agree that we are free to use it without any restriction or compensation to you.</p>
        <p><strong>6. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Subscriptions.</strong> {{ company }} requires payment of a fee for use of some of the Services (or certain portions thereof) and you agree to pay such fees for non-free Services.</p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>General. When you purchase a subscription for the Services (“<strong>Subscription</strong>”), you expressly authorize us (or our third-party payment processor) to charge you for such Subscription. We may ask you to supply additional information relevant to your Subscription, including your credit card number, the expiration date of your credit card and your email and postal addresses for billing and notification (such information, “<strong>Payment Information</strong>”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. When you initiate a Subscription, you authorize us to provide your Payment Information to third parties so we can complete the transaction related to your Subscription and to charge your payment method for the type of Subscription you have selected (plus any applicable taxes and other charges). You may need to provide additional information to verify your identity before completing your Subscription (such information is included within the definition of Payment Information). By initiating a Subscription, you agree to the pricing, payment and billing policies applicable to such fees and charges, as posted or otherwise communicated to you. All payments for Subscriptions are non-refundable and non-transferable except as expressly provided in these Terms. All fees and applicable taxes, if any, are payable in United States dollars.</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Subscriptions. If you purchase a Subscription, you will be charged the annual Subscription fee, plus any applicable taxes, and other charges (“<strong>Subscription Fee</strong>”), at the beginning of your Subscription and each year thereafter, at the then-current Subscription Fee. BY PURCHASING A SUBSCRIPTION, YOU AUTHORIZE {{ company }} TO INITIATE RECURRING NON-REFUNDABLE PAYMENTS AS SET FORTH BELOW. If you purchase a Subscription, we (or our third-party payment processor) will automatically charge you each year on the anniversary of the commencement of your Subscription, using the Payment Information you have provided until you cancel your Subscription. No less than thirty (30) days and no more than sixty (60) days before your Subscription term ends, or otherwise in accordance with applicable law, {{ company }} will send you a reminder with the then-current Subscription Fee. By agreeing to these Terms and electing to purchase a Subscription, you acknowledge that your Subscription has recurring payment features and you accept responsibility for all recurring payment obligations prior to cancellation of your Subscription by you or {{ company }}. Your Subscription continues until cancelled by you or we terminate your access to or use of the Services or Subscription in accordance with these Terms.</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Cancelling Your Subscription. You may cancel your Subscription for a full refund within ten (10) calendar days of your initial purchase. AFTER THAT, YOUR PURCHASE IS FINAL AND YOU WILL NOT BE ABLE TO CANCEL THE PURCHASE AND/OR RECEIVE A REFUND OF YOUR SUBSCRIPTION FEE AT ANY TIME. But if something unexpected happens in the course of completing a transaction, we reserve the right to cancel your transaction for any reason; if we cancel your transaction we will refund any payment you have already remitted to us for such transaction. Without limiting the foregoing, you may cancel your Subscription at any time, but please note that such cancellation will be effective at the end of the then-current Subscription period. EXCEPT AS SET FORTH ABOVE WITH RESPECT TO YOUR INITIAL SUBSCRIPTION PURCHASE, YOU WILL NOT RECEIVE A REFUND OF ANY PORTION OF THE SUBSCRIPTION FEE PAID FOR THE THEN CURRENT SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION. To cancel, you can send an email to <a :href="'mailto:' + cancelEmail">{{ cancelEmail }}</a>. You will be responsible for all Subscription Fees (plus any applicable taxes and other charges) incurred for the then-current Subscription period. If you cancel, your right to use the Services will continue until the end of your then current Subscription period and will then terminate without further charges.</p>
        <p><strong>(d) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Free Trials. We may offer to certain qualifying users paid Subscriptions on a free trial basis (“<strong>Free Trial</strong>”) for a specified period of time. If we offer you a Free Trial, the specific terms of your Free Trial will be provided at signup. Your use of the Free Trial is subject to your compliance with such specific terms. Certain limitations may exist with respect to combining Free Trials with any other offers. Except as may otherwise be provided in the specific terms for the Free Trial offer, Free Trials are only available to users who have not previously held a paid Subscription. When you agree to a Free Trial, you are also agreeing to sign up for a paid Subscription as described above and, consequently, unless you cancel your Subscription prior to the end of your Free Trial, we (or our third party payment processor) will begin charging your payment method on a recurring annual basis for the applicable annual fee (plus any applicable taxes and other charges) at the end of the Free Trial until you cancel your Subscription. If you wish to avoid charges to your Payment Information, you must cancel your Subscription prior to midnight Mountain Time on the last day of your Free Trial period. Instructions for cancelling your subscription are stated in the section above titled “Canceling Your Subscription.”</p>
        <p><strong>7. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Your Content.</strong></p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Posting Content. Our Services may allow you to store or share content such as embeddable widget configuration, text (in posts or communications with others), files, documents, graphics, images, music, software, audio and video. Anything (other than Feedback) that you post or otherwise make available through the Services is referred to as “<strong>User Content</strong>.” {{ company }} does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to your User Content.</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Permissions to Your User Content. By making any User Content available through the Services you hereby grant to {{ company }} a non-exclusive, transferable, worldwide, royalty-free, fully paid-up license, with the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform your User Content in connection with operating and providing the Services.</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Your Responsibility for User Content. You are solely responsible for all your User Content. You represent and warrant that you have (and will have) all rights that are necessary to grant us the license rights in your User Content under these Terms. You represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by {{ company }} on or through the Services will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
        <p><strong>(d) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Removal of User Content. You can remove your User Content by specifically deleting it. You should know that in certain instances, some of your User Content, (such as posts or comments you make), may not be completely removed and copies of your User Content may continue to exist on the Services. To the maximum extent permitted by law, we are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.</p>
        <p><strong>(e) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Your Use of Generated Content. Subject to your compliance with these Terms, {{ company }} hereby grants you a non-exclusive, transferable, worldwide, perpetual license, with the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform Generated Content for your lawful business purposes.</p>
        <p><strong>(f) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>{{ company }}’ Intellectual Property. We may make available through the Services content that is subject to intellectual property rights, including Generated Content. We retain all rights to that content.</p>
        <p><strong>8. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; General Prohibitions and {{ company }}’ Enforcement Rights</strong>. You agree not to do any of the following:</p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Use, display, mirror or frame the Services or any individual element within the Services, {{ company }}’ or BlockBase’s name, any {{ company }} or BlockBase trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without {{ company }}’ express written consent;</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Access, tamper with, or use non-public areas of the Services, {{ company }}’ computer systems, or the technical delivery systems of {{ company }}’ providers;</p>
        <p><strong>(d) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Attempt to probe, scan or test the vulnerability of any {{ company }} system or network or breach any security or authentication measures;</p>
        <p><strong>(e) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by {{ company }} or any of {{ company }}’ providers or any other third party (including another user) to protect the Services;</p>
        <p><strong>(f) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>Attempt to access or search the Services or download content from the Services using any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by {{ company }} or other generally available third-party web browsers;</p>
        <p><strong>(g) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;</p>
        <p><strong>(h) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Use any meta tags or other hidden text or metadata utilizing a {{ company }} trademark, logo URL or product name without {{ company }}’ express written consent;</p>
        <p><strong>(i) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</p>
        <p><strong>(j) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive or false source-identifying information;</p>
        <p><strong>(k) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services;</p>
        <p><strong>(l) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</p>
        <p><strong>(m) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</p>
        <p><strong>(n) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Impersonate or misrepresent your affiliation with any person or entity;</p>
        <p><strong>(o) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Violate any applicable law or regulation; or</p>
        <p><strong>(p) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Encourage or enable any other individual to do any of the foregoing.</p>
        <p>{{ company }} is not obligated to monitor access to or use of the Services or to review or edit any content. However, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any content, including User Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</p>
        <p><strong>9. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; DMCA/Copyright Policy.</strong> {{ company }} respects copyright law and expects its users to do the same. It is {{ company }}’ policy to terminate in appropriate circumstances account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.</p>
        <p><strong>10. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Links to Third Party Websites or Resources.</strong> The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party resources.</p>
        <p><strong>11. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Termination.</strong> We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by sending us an email at <a :href="'mailto:' + cancelEmail">{{ cancelEmail }}</a>. Upon any termination, discontinuation or cancellation of the Services or your account, the following Sections will survive: 6(a), 6(b), 6(c) (only for payments due and owing to {{ company }} prior to the termination), 6(d), 7(b), 7(c), 7(e), 7(f), 9, 11, 12, 13, 14, 15, 16 and 17.</p>
        <p><strong>12. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Warranty Disclaimers.</strong> THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any information or content on the Services.</p>
        <p><strong>13. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indemnity.</strong> You will indemnify and hold {{ company }} and its officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services, (b) your User Content, or (c) your violation of these Terms.</p>
        <p><strong>14. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Limitation of Liability.</strong></p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER {{ company }} NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT {{ company }} OR ITS SERVICE PROVIDERS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL {{ company }}’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO {{ company }} FOR USE OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO {{ company }}, AS APPLICABLE.</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN {{ company }} AND YOU.</p>
        <p>
          <strong>15. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Governing Law and Forum Choice.</strong> These Terms and any action related thereto will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of Delaware, without regard to its conflict of laws provisions. Except as otherwise expressly set forth in <router-link to="/terms#16">
            Section 16 “Dispute Resolution,”
          </router-link> the exclusive jurisdiction for all Disputes (defined below) that you and {{ company }} are not required to arbitrate will be the state and federal courts located in Delaware, and you and {{ company }} each waive any objection to jurisdiction and venue in such courts.
        </p><div class="w-embed">
          <div id="16" />
        <p><strong>16. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dispute Resolution.</strong></p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Mandatory Arbitration of Disputes. We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “<strong>Disputes</strong>”) will be resolved <strong>solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding</strong>. You and {{ company }} agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that you and {{ company }} are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms.</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Exceptions. As limited exceptions to Section 16(a) above: (i) we both may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the American Arbitration Association (“<strong>AAA</strong>”) under its Consumer Arbitration Rules (the “<strong>AAA Rules</strong>”) then in effect, except as modified by these Terms. The AAA Rules are available at <a href="http://www.adr.org">www.adr.org</a> or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175">www.adr.org</a>.</p>
        <p>Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.</p>
        <p><strong>(d) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.</p>
        <p><strong>(e) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Injunctive and Declaratory Relief. Except as provided in Section 16(b) above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration.</p>
        <p><strong>(f) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>Class Action Waiver. <strong>YOU AND {{ company }} AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</strong> Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.</p>
        <p><strong>(g) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Severability. With the exception of any of the provisions in Section 16(f) of these Terms (“<strong>Class Action Waiver</strong>”), if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply.</p>/div>
        <p><strong>17. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;General Terms.</strong></p>
        <p><strong>(a) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Reservation of Rights. {{ company }} and its licensors exclusively own all right, title and interest in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</p>
        <p><strong>(b) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Entire Agreement. These Terms constitute the entire and exclusive understanding and agreement between {{ company }} and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between {{ company }} and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without {{ company }}’ prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. {{ company }} may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>
        <p><strong>(c) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Notices. Any notices or other communications provided by {{ company }} under these Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.</p>
        <p><strong>(d) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong>Waiver of Rights. {{ company }}’ failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of {{ company }}. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</p>
        <p><strong>18. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Contact Information.</strong> If you have any questions about these Terms or the Services, please contact {{ company }} at:</p>
      </div>
      <p>
        {{ companyFull }}<br>
        &nbsp;<br>
        {{ addr[0] }}<br>
        {{ addr[1] }}<br>
        {{ addr[2] }}<br>
        &nbsp;<br>
        <a :href="'mailto:' + legalEmail">
          {{ legalEmail }}
        </a><br>
        &nbsp;<br>
        {{ phone }}
      </p>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lastUpdate: 'May 30th 2021',
      company: 'New Moon Labs',
      site: 'blockbase.dev',
      companyFull: 'New Moon Labs, Inc.',
      legalEmail: 'hello@newmoonlabs.com',                                                                                              cancelEmail: 'hello@newmoonlabs.com',
      phone: '877-960-1004',
      addr: ['New Moon Labs', '800 Copper Road, #3042', 'Copper Mountain, CO 80443, USA']
    }                                                                                                                               }
}
</script>
